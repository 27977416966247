import React from 'react';
import Dashboard from 'pages/Dashboard';
import ThemeWrapper from './theme/ThemeWrapper';

function App() {
  return (
    <ThemeWrapper>
      <Dashboard />
    </ThemeWrapper>
  );
}

export default App;

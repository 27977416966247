import styled from 'styled-components';
import Image from 'components/Base/Image';

export const SImage = styled(Image)`
  display: block;
  margin-bottom: 32px;
  width: 100%;
  max-width: 254px;
`;

export const SLink = styled.a`
  :hover {
    text-decoration: none;
  }
`;

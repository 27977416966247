import styled from 'styled-components';
import { Title } from 'components/Base/Typography';
import Image from 'components/Base/Image';
import { Col } from 'components/Base/Grid';
import { Screens } from 'core/CssVariables';

export const STitle = styled(Title)`
  &&& {
    margin-top: 12px;
    margin-bottom: 40px;
  }
`;

export const SImage = styled(Image)`
  max-width: 280px;
  width: 100%;
`;

export const SCol = styled(Col)`
  margin: 32px 40px;
`;

export const SPageWrapper = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${Screens.ScreensMD}) {
    height: auto;
    padding-top: 48px;
  }
`;

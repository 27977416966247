import React from 'react';
// import { Button as UIKitButton } from '@landlord-tech/ui-kit-opp';
import { TButtonProps, EButtonTypes, EButtonColors } from './type';
import SButton from './style';

const Button = ({
  type = EButtonTypes.Primary,
  color = EButtonColors.Primary,
  outlined = false,
  ...props
}: TButtonProps) => {
  return <SButton type={type} color={color} outlined={outlined} {...props} />;
};

export { EButtonColors, EButtonTypes };
export default Button;

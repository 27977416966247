import React from 'react';
import { Colors, FontWeights } from 'core/CssVariables';
import Logo from 'assets/images/Logo.svg';
import House from 'assets/images/House.svg';
import HumanAndPet from 'assets/images/HumanAndPet.svg';
import { Row } from 'components/Base/Grid';
import { EUserType } from './Dashboard.types';
import ProjectSection from './components/ProjectSection';
import { STitle, SCol, SPageWrapper, SImage } from './Dashboard.styles';

const usersData = [
  {
    src: House,
    userType: EUserType.LANDLORD,
    link: process.env.REACT_APP_LANDLORD_APP_HOSTNAME || '',
  },
  {
    src: HumanAndPet,
    userType: EUserType.RESIDENT,
    link: process.env.REACT_APP_RESIDENT_APP_HOSTNAME || '',
  },
];

const Dashboard = () => {
  return (
    <SPageWrapper>
      <SImage src={Logo} alt="Our Pet Policy" />
      <STitle
        fontWeight={FontWeights.Medium}
        color={Colors.Black}
        fontLevel={3}
      >
        Hello and welcome!
      </STitle>
      <Row justify="center">
        {usersData.map((user, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <SCol key={i}>
              <ProjectSection data={user} />
            </SCol>
          );
        })}
      </Row>
    </SPageWrapper>
  );
};

export default Dashboard;

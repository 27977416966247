import styled from 'styled-components';
import { Button as UIKitButton } from '@landlord-tech/ui-kit-opp';

const SUIKitButton = styled(UIKitButton)`
  &&& {
    .icon {
      margin-right: 4px !important;
    }
  }
`;

export default SUIKitButton;

import React from 'react';
import Button from 'components/Base/Button';
import { TProjectSectionProps } from './ProjectSection.types';
import { SImage, SLink } from './ProjectSection.styles';

const ProjectSection = ({ data }: TProjectSectionProps) => {
  const { src, userType, link } = data;

  return (
    <>
      <SImage src={src} alt="" />
      <SLink href={link} rel="noreferrer" className="link-of-button">
        <Button>Continue as a {userType}</Button>
      </SLink>
    </>
  );
};

export default ProjectSection;
